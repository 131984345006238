<template>
  <div class="fill-height d-flex justify-center flex-column">
    <div>
      <!--      <img width="300px" src="../../../assets/new-design/step-1-img.svg" alt="">-->
      <!--      <p>-->
      <!--        جلسه پیش‌مشاوره شما تنظیم شد. شما می‌توانید این زمان را به تقویم گوگل خود اضافه کنید.-->
      <!--      </p>-->
      <div class="sessionCard">
        <v-row class="d-flex">
          <v-col cols="12" lg="8" class="flex-grow-1 text-right" style="font-size: 15px;line-height: 25pt"

                 :style="$vuetify.breakpoint.mdAndDown && 'margin-top:170px'"
                 v-if="!preSession">
            جلسه شما با دکتر
            {{ doctor.full_name }}
            تنظیم شد.
            شما می‌توانید این زمان را به تقویم گوگل خود اضافه کنید.
          </v-col>
          <v-col cols="12" lg="9" class="flex-grow-1 text-right" style="font-size: 15px;line-height: 25pt" v-else>
            جلسه پیش مشاوره متناسب با
            <span>
              <u>
                زمان محلی شما
              </u>
            </span>
            تنظیم شد.
          </v-col>
          <div>
            <!--            vsdv-->
          </div>
          <v-col cols="12" lg="4" v-if="!preSession">
            <div>
              <img :src="doctor.avatar" width="100px" alt="">
            </div>
            <a :href="doctor.resume_link" v-if="doctor.hasOwnProperty('resume_link')" target="_blank">
              <v-btn color="primary" small text style="position: relative;top: -13px">
                مشاهده رزومه
              </v-btn>
            </a>
          </v-col>
        </v-row>
        <div class="text-right mb-5 mt-1" v-if="selectedTime">

          <div class="d-flex " :class="$vuetify.breakpoint.mdAndDown && 'flex-column'"
               style="font-size: 17px;color: rgb(80, 130, 130);">
            <div class="my-1">
              {{ DateFilter(selectedTime.time.start) }}
            </div>
            <div class="mx-1" v-if="$vuetify.breakpoint.mdAndUp">
              |
            </div>
            <div class="my-1">
              {{ gorgianFilter(selectedTime.time.start) }}
            </div>
            <div class="mx-1" v-if="$vuetify.breakpoint.mdAndUp">
              |
            </div>
            <div class="my-1">
              <b>
                {{ timeFilter(selectedTime.time.start) }}
                ({{ profile.tz }})
              </b>
            </div>
          </div>
          <div class="font-weight-bold mt-2" style="line-height: 25pt">
            <ul class="pr-10">
              <li>
                لطفا همین حالا به آیدی زیر در اسکایپ پیام دهید و نام خود را ارسال کنید.
              </li>
              <li>
                لازم است در زمان مقرر با همین آیدی تماس بگیرید تا جلسه برگزار شود.
              </li>

            </ul>
          </div>
          <div class="d-flex justify-center my-3">
            <a style="text-decoration: none;color: inherit;font-size: 12px"
               target="_blank"
               class=""
               :href="skypeId">
              <v-btn outlined color="#3CBE8B" class="d-flex justify-center black--text">
                <div class="d-flex flex-column justify-center">
                  آیدی اسکایپ مشاور
                </div>
                <img class="mr-1" src="../../../assets/skype.svg" width="25px" alt="">
              </v-btn>
            </a>
          </div>
          <div style="font-size: 12px" class="mt-5">
            همچنین می‌توانید زمان جلسه را به تقویم گوگل خود اضافه کنید.
          </div>

        </div>
        <div class="d-flex justify-center ">
          <a style="text-decoration: none;color: inherit;font-size: 12px"
             target="_blank"
             class=""
             :href="url">
            <v-btn outlined color="#3CBE8B" class="d-flex justify-center black--text">
              <div class="d-flex flex-column justify-center">
                افزودن به Google Calendar
              </div>
              <img class="mr-1" src="../../../assets/google_calender.png" width="30px" alt="">
            </v-btn>
          </a>
        </div>

      </div>
      <!--      <v-btn text class="" small style="text-transform: none">-->

      <!--      </v-btn>-->
      <!--      <br>-->
      <v-btn class="NextBtn" style="position: relative;z-index: 10;" @click="goToDashboard" color="#3CBE8B"
             elevation="0">
        {{ actionText }}
      </v-btn>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import {mapGetters} from "vuex";
import moment from "moment-timezone";
import momentJalali from "moment-jalaali";

export default {
  computed: {
    ...mapGetters({
      selectedTime: 'getSelectedTime',
      profile: 'getProfile',
      skypeId: 'getSkypePreSession'
    }),
    doctor() {
      return this.profile.assignedDoctor
    },
    url() {
      return `https://www.google.com/calendar/render?action=TEMPLATE&text=جلسه پیش‌مشاوره&dates=${this.start}/${this.end}&details=Event%20Description&location=Event%20Location`
    }
  },
  props: {
    preSession: Boolean,
    text: String,
    // جلسه پیش‌مشاوره شما تنظیم شد. شما می‌توانید این زمان را به تقویم گوگل خود اضافه کنید.

    actionText: String,
    // رفتن به داشبورد
  },
  data() {
    return {
      start: null,
      end: null,
    }
  },
  mounted() {

    if (this.$route.name == 'onboarding_presession' || this.$route.name == 'onboarding_new') {
      if (window.webengage) {
        window.webengage.track('presession_submit', {
          date: new Date(),
        });
      }
    }

    this.start = this.convertTZ(this.selectedTime.time.start, this.profile.tz).format("YYYYMMDDTHHmmss") + "Z"
    this.end = this.convertTZ(this.selectedTime.time.end, this.profile.tz).format("YYYYMMDDTHHmmss") + "Z"
    console.log(this.start, this.end)
  },
  methods: {
    DateFilter(date) {
      return this.$t(`days.${momentJalali(date).format('dddd')}`) + " " + momentJalali(date).format("jYYYY/jMM/jDD")
    },
    gorgianFilter(date) {
      return moment(date).format('dddd') + " " + moment(date).format("YYYY/MM/DD")
    },
    goToDashboard() {
      this.$emit('nextHandler')
    },
    convertTZ(time, tz) {
      var current = moment.tz(time, tz);
      return current.clone().utc();
    },
    timeFilter(date) {
      return moment(date).format("HH:mm")

    }
  }
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {

  .NextBtn {
    width: 100% !important;
    margin-top: -50px !important;
  }

  .sessionCard {
    width: 100% !important;
  }
}

.NextBtn {
  border-radius: 9px !important;
  font-size: 15px;
  color: white;
}

.sessionCard {

  width: 90%;
  min-height: 130px;
  background: white;
  border-radius: 18px;
  padding: 25px;
  /*border: 1px solid #3CBE8B;*/
  /*box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.1);*/
  margin: 50px auto 20px auto;
  z-index: 10;
  position: relative;
}

</style>